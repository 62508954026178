import Vue from 'vue'
import VueRouter from 'vue-router'

// 避免重复导航
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/receive/:projectCode?',
    name: 'receive',
    props: true,
    component: () => import('@/views/ReceiveView')
  },
  {
    path: '/send/:projectCode?',
    name: 'send',
    props: true,
    component: () => import('@/views/SendView'),
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})


export default router
